import { SORT_BY_DATE } from '../../constants/common';
import { MONTHLY_RATE_TYPE } from '../../constants/types';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { formatStringDate } from '../../utils/helpers';
import {
  SALARY_BASE_FETCH_DATA, SALARY_BASE_LIST_SET_SUBMIT,
  SALARY_BASE_RESET_DATEPICKER,
  SALARY_BASE_RESET_FILTERS, SALARY_BASE_RESET_MODAL, SALARY_BASE_RESET_STATE,
  SALARY_BASE_SET_ABORT,
  SALARY_BASE_SET_DATEPICKER_DATA,
  SALARY_BASE_SET_LOG_ERROR, SALARY_BASE_SET_MODAL_DATA, SALARY_BASE_SET_MODAL_FIELDS, SALARY_BASE_SET_PAGE,
  SALARY_BASE_SET_PAGE_FILTER_DATA, SALARY_BASE_SET_QUERY_PARAMS, SALARY_BASE_SET_SORT,
  SALARY_BASE_SET_TABLE_COLUMNS, SALARY_BASE_SET_TABLE_HEAD_DATA
} from './types';

const today = new Date();
const formatToday = formatStringDate(today, 'dd.MM.yyyy');

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  isImportSubmit: false,
  modalErrors: {},
  tableColumns: {
    employee: true,
    startDate: true,
    rateType: false,
    department: true,
    rate: true,
    rateBench: false,
    overTimeHourlyRate: false,
    cardNumber: false,
    preferredPaymentMethod: false,
    loggingMode: false,
    vacationFactor: false,
    leaveFactor: false,
    createdAt: false,
    createdBy: false,
    comment: false,
    action: true
  },
  queryParams: {
    filters: {
      id: [],
      user_name: '',
      user_id: [],
      active_users: true,
      actual_salary_on_date: '',
      start_date: '',
      vacation_factor: '',
      sickness_leave_factor: '',
      is_padded: false,
      show_inactive: false,
      department_id: [],
      // use_builder: undefined
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    sort: `-${SORT_BY_DATE}`
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    selectedEmployeeName: [],
    isColumnsOpen: false,
    departmentName: '',
    isDepartmentOpen: false,
    selectedDepartmentName: []
  },
  tableHeadData: {
    vacationInputValue: '',
    sicknessInputValue: '',
  },
  salaryBaseModal: {
    modalId: 'salaryBaseModal',
    isModalOpen: false,
    isDateOpen: false,
    isRateTypeOpen: false,
    isEmployeeOpen: false,
    isLoggingModeOpen: false,
    isDepartmentOpen: false,
    searchDepartmentValue: '',
    searchEmployeeValue: ''
  },
  salaryBaseFields: {
    id: null,
    employee: null,
    department: null,
    start_date: today,
    rate_type: MONTHLY_RATE_TYPE,
    rate: '',
    overtime_hourly_rate: '0',
    logging_mode: 'full-time-delivery',
    vacation_factor: '1',
    sickness_leave_factor: '0.75',
    // use_builder: false,
    rate_bench: '0',
    card_number: '',
    preferred_payment_method: null
  },
  salaryBaseWarningModal: {
    modalId: 'salaryBaseWarningModal',
    isModalOpen: false
  },
  salaryBaseImportModal: {
    modalId: 'salaryBaseImportModal',
    isModalOpen: false,
    data: [],
    isImportLoading: false
  },
  importResultModal: {
    modalId: 'importResultModal',
    isModalOpen: false,
    countSuccess: null
  },
  datepicker: {
    from: today,
    to: today,
    enteredTo: today,
    inputFrom: formatToday,
    inputEnteredTo: formatToday,
    isSelectRange: false,
    isOpen: false,
    style: {}
  },
  abortProps: {
    abortSalaryBase: {}
  },
};

export default function salaryBase(state = initialState, action) {
  switch (action.type) {
    case SALARY_BASE_RESET_DATEPICKER:
      return {
        ...state,
        datepicker: initialState.datepicker
      };
    case SALARY_BASE_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.data
      };
    case SALARY_BASE_RESET_FILTERS:
      return {
        ...state,
        queryParams: {
          ...initialState.queryParams,
          filters: {
            ...state.queryParams.filters,
            active_users: false,
            actual_salary_on_date: '',
          }
        },
      };
    case SALARY_BASE_RESET_MODAL:
      return {
        ...state,
        salaryBaseFields: initialState.salaryBaseFields,
        modalErrors: initialState.modalErrors,
        salaryBaseModal: initialState.salaryBaseModal,
        salaryBaseImportModal: initialState.salaryBaseImportModal,
        importResultModal: initialState.importResultModal
      };
    case SALARY_BASE_RESET_STATE:
      return initialState;
    case SALARY_BASE_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SALARY_BASE_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    case SALARY_BASE_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case SALARY_BASE_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case SALARY_BASE_SET_MODAL_FIELDS:
      return {
        ...state,
        salaryBaseFields: {
          ...state.salaryBaseFields,
          ...action.data
        }
      };
    case SALARY_BASE_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case SALARY_BASE_SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          ...action.columns
        }
      };
    case SALARY_BASE_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    case SALARY_BASE_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SALARY_BASE_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        }
      };
    case SALARY_BASE_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALARY_BASE_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    default:
      return state;
  }
}
