import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

export const initialState = {
  isLoading: false,
  error: undefined,
  currencies: [],
  projectTypes: [],
  salaryCorrectionTypes: [],
  salaryAnomalyStatus: [],
  salaryEmailStatus: [],
  salaryXeroStatus: [],
  documentationType: [],
  salaryLoggingMode: [],
  rateTypes: [],
  usageLevel: [],
  agreementCompanies: [],
  preferredPaymentMethods: [],
  projectItemTypes: [],
  milestoneItemWorkloadTypes: [],
  serviceRateType: [],
  preferredBillingPeriod: [],
  milestoneType: [],
  clientProductNDAStatus: [],
  termsList: [],
  departmentEnum: [],
  invoiceItemCategoryEnum: [],
  salaryBonusCategoryEnum: [],
  salaryCorrectionTypeEnum: [],
  userLanguageLevelEnum: [],
  cvUserLevelEnum: [],
  cvUserPositionEnum: [],
  projectContractTypeEnum: [],
  clientFeedbackSourceEnum: [],
};

export const fetchEnumsAsync = createAsyncThunk(
  'enums/fetchEnums',
  async ({ key, enumName, filters }, { rejectWithValue }) => {
    try {
      const params = {
        filters: {
          ...filters,
          only_enum: enumName ? [enumName] : [],
        }
      };

      const { data, status } = await axios.get('/app/enum', { params });

      const res = Object.entries(data[0]?.values || []);

      return status === 200 ? { key, data: res } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const enumsSlice = createSlice({
  name: 'enums',
  initialState,
  reducers: {
    setEnum: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnumsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEnumsAsync.fulfilled, (state, action) => {
        state[action.payload.key] = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchEnumsAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setEnum } = enumsSlice.actions;

export default enumsSlice.reducer;
