import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { OK } from '../../constants/httpStatusCodes';
import axios from '../../services/axios';

export const initialState = {
  isLoading: false,
  error: undefined,
  data: {
    general: {},
  }
};

export const fetchSettingsAsync = createAsyncThunk(
  'settings/fetchSettings',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/settings');

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSettingsAsync.fulfilled, (state, action) => {
        action.meta.arg.settingsKeys.forEach((key) => {
          const updateKey = key[0].toLowerCase() + key.slice(1);

          state.data = { ...initialState.data, [updateKey]: action.payload[key] };
        });
        state.isLoading = false;
      })
      .addCase(fetchSettingsAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default settingsSlice.reducer;
