import { wrapToArray } from './arrayHelpers';

export const hasProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

export const isObject = (obj) => {
  return obj && obj.constructor === Object;
};

export const isEmpty = (obj) => {
  return isObject(obj) ? Object.keys(obj).length === 0 : true;
};

export const isNotEmpty = (obj) => {
  return Object.values(obj).filter((value) => value !== null).length > 0;
};

export const isEqual = (...objects) => objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const removeNullUndefined = (obj) => {
  return Object.entries(obj)
    .filter(([, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const removeEmptyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => value)
      .map(([key, value]) => [
        key,
        isObject(value) ? removeEmptyValues(value) : value,
      ])
  );
};

export const getKeysValues = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);

  return { keys, values };
};

export const compactObject = (data) => {
  if (typeof data !== 'object') {
    return data;
  }

  return Object.keys(data).reduce((accumulator, key) => {
    const isObj = typeof data[key] === 'object';
    let value = isObj ? compactObject(data[key]) : data[key];
    const isEmptyObject = isObj && !Object.keys(value).length;

    if (value === undefined || value === '' || value === null || isEmptyObject) {
      return accumulator;
    }

    if (Array.isArray(data[key])) {
      value = Object.values(value);
    }

    return { ...accumulator, [key]: value };
  }, {});
};

export const omit = (obj, keys) => {
  return Object.keys(obj)
    .filter((k) => !keys.includes(k))
    .reduce((res, k) => Object.assign(res, { [k]: obj[k] }), {});
};

export const convertStringToDeepObject = (str, toArray = false) => {
  const [keyString, value] = str.split('=');
  const keys = keyString.split(/[\\[\]]+/).filter(Boolean);

  const obj = {};

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = toArray ? wrapToArray(value) : value;
    } else {
      acc[key] = acc[key] || {};
    }

    return acc[key];
  }, obj);

  return { keys, obj };
};
