export const generateId = () => crypto.randomUUID();

export const updateOrAddCVFields = (dataFieldsList, itemField) => {
  let newArr = [...dataFieldsList];

  if (itemField?.id) {
    const findIndex = newArr.findIndex((item) => item.id === itemField.id);
    newArr[findIndex !== -1 ? findIndex : newArr.length] = itemField;
  } else {
    newArr = [...newArr, { ...itemField, id: generateId() }];
  }

  return newArr;
};

const getFormattedData = (item, itemKey) => {
  if (!item) return {};

  switch (itemKey) {
    case 'project':
      return {
        id: item.id,
        name: item.name,
        features: item?.features?.flatMap(({ name }) => (name.trim() ? [name] : [])) || undefined,
        short_description: item.shortDescription || undefined,
        start_year: item.startYear || undefined,
        end_year: item.endYear || undefined,
        is_internal: item.isInternal || false,
        under_nda: item.underNda || false,
        technologies: item.technologies || undefined
      };

    case 'certification':
      return {
        id: item.id,
        ...item.name && { name: item.name },
        ...item.link && { url: item.link },
        ...item.description && { description: item.description },
        ...item.isShow && { show_in_header: item.isShow }
      };

    case 'education':
      return {
        id: item.id,
        ...item.name && { name_of_institution: item.name },
        ...item.degree && { specialty: item.degree },
        ...item.startDate && { start_year: item.startDate },
        ...item.endDate && { end_year: item.endDate },
      };

    default:
      return item;
  }
};

export const getRequestItem = (listItems, itemData, itemKey, isDelete = false) => {
  let requestData = listItems?.map((item) => getFormattedData(item, itemKey));

  if (itemData?.id) {
    const findIndexProject = listItems.findIndex((item) => item.id === itemData.id);
    const projectItemData = getFormattedData(itemData, itemKey);

    if (isDelete) {
      requestData = requestData.filter((item) => item.id !== itemData.id);
    } else if (findIndexProject > -1) {
      requestData = requestData?.map((item) => (item.id === itemData.id ? projectItemData : item));
    } else {
      requestData = [...requestData, projectItemData];
    }
  }

  return requestData;
};

export const filterAndReindex = (array) => {
  return array.filter((item) => item.name.trim() !== '')
    .map((item, index) => ({
      ...item,
      id: index + 1
    }));
};
