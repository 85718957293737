import { useEffect } from 'react';
import { batch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from '../hooks/redux';
import { useDispatch, useSelector } from '../hooks/reduxToolkit';
import useNotificationInterceptor from '../hooks/useNotificationInterceptor';
import { fetchApiInfo } from '../store/general/actions';
import { wsConnect, wsDisconnect } from '../store/websocket/actions';
import { fetchNotificationsAsync } from '../toolkitStore/notificationsModal/slice';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

import styles from './sass/Layout.module.scss';

const Layout = ({ children }) => {
  const reduxDispatch = useReduxDispatch();
  const dispatch = useDispatch();

  const apiInfo = useReduxSelector(({ general }) => general.apiInfo, () => true);

  const {
    userIsLoading,
    userPermissions,
    passportId,
    profileId,
  } = useSelector(({ user }) => ({
    userIsLoading: user.isLoading,
    userPermissions: user.permissions,
    passportId: user.profile?.passport_id,
    profileId: user.profile?.id,
  }), shallowEqual);

  useNotificationInterceptor();

  useEffect(() => {
    batch(() => {
      dispatch(fetchNotificationsAsync());

      if (!userIsLoading && userPermissions.length > 0) {
        reduxDispatch(wsConnect(profileId));

        if (!apiInfo.version) {
          reduxDispatch(fetchApiInfo());
        }
      }
    });
    return () => {
      reduxDispatch(wsDisconnect());
    };
  }, [userPermissions]);

  return (
    <>
      <Header />
      <div className={styles.container}>
        {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
          <img src={`https://my.onix.team/ping/${passportId}`} width="1" height="1" alt="icon" hidden />
        )}
        <Sidebar />
        {children}
      </div>
      <div id="modal-container" />
      <div id="dropdown-container" />
      <div id="alert-container" />
      <div id="tip-container" />
      <div id="hover-card-container" />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
