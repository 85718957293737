export const CURRENCY_ENUM = 'CurrencyEnum';
export const DOCUMENTATION_TYPE_ENUM = 'DocumentationTypeEnum';
export const PROJECT_TYPE_ENUM = 'ProjectTypeEnum';
export const SALARY_LOGGING_MODE_ENUM = 'SalaryBaseLoggingModeEnum';
export const SALARY_ANOMALY_STATUS_ENUM = 'SalaryAnomalyStatusEnum';
export const SALARY_EMAIL_STATUS_ENUM = 'SalaryEmailStatusEnum';
export const SALARY_XERO_STATUS_ENUM = 'SalaryXeroStatusEnum';
export const RATE_TYPE_ENUM = 'SalaryBaseRateTypeEnum';
export const TECHNOLOGY_USAGE_LEVEL = 'UserTechnologyUsageLevelEnum';
export const AGREEMENT_COMPANY_ENUM = 'AgreementCompanyEnum';
export const PREFERRED_PAYMENT_METHOD = 'SalaryBasePreferredPaymentMethodEnum';
export const SERVICE_RATE_TYPE_ENUM = 'ClientAgreementServiceRateTypeEnum';
export const PREFERRED_BILLING_PERIOD = 'PreferredBillingPeriodEnum';
export const MILESTONE_TYPE = 'ClientProductMilestoneTypeEnum';
export const CLIENT_PRODUCT_DNA_STATUS_ENUM = 'ClientProductNDAStatus';
export const DEPARTMENT_ENUM = 'DepartmentEnum';
export const INVOICE_ITEM_CATEGORY_ENUM = 'InvoiceItemCategoryEnum';
export const SALARY_BONUS_CATEGORY_ENUM = 'SalaryBonusCategoryEnum';
export const SALARY_CORRECTION_TYPE_ENUM = 'SalaryCorrectionTypeEnum';
export const SUBSCRIPTION_TERMS_ENUM = 'SubscriptionTermsEnum';
export const LANGUAGE_LEVEL_ENUM = 'UserLanguageLevelEnum';
export const PROJECT_CONTRACT_TYPE_ENUM = 'ProjectContractTypeEnum';
export const CV_USER_LEVEL_ENUM = 'CvUserLevelEnum';
export const CV_USER_POSITION_ENUM = 'CvUserPositionEnum';
export const CLIENT_FEEDBACK_SOURCE_ENUM = 'ClientFeedbackSourceEnum';
export const ROLE_GLOBAL_ENUM = 'RoleGlobalEnum';
export const ROLE_PROJECT_ENUM = 'RoleProjectEnum';
