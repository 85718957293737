import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { CV_FIELD_TYPES_TEMPLATES } from '../../constants/CVGenerator';
import { CREATED, NO_CONTENT, OK } from '../../constants/httpStatusCodes';
import { CV_MANAGEMENT } from '../../constants/permissions';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import axios from '../../services/axios';
import { sortBy } from '../../utils/arrayHelpers';
import { getRequestItem } from '../../utils/CVHelper';
import { isAllowed, prepareRequestBody, urlToFile } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';
import { validateErrors } from '../../utils/validators';
import { addEmptyDescriptionIds } from '../systemsCVGenerator/slice';

export const initialState = {
  isFormHidden: false,
  isLoading: true,
  isEditing: true,
  cvsPdfData: null,
  isProjectLoading: false,
  maxVersion: 0,
  employeesProps: {
    employee: null,
    employeeId: null,
  },
  query: {
    filters: { project_name: '', type: '' },
    page: null,
  },
  strengthsProps: {
    strengthList: [],
  },
  previousRenderValue: null,
  technologiesProps: {
    technologyName: '',
    technologyList: [],
    currentTechnology: null,
    isModalOpen: false,
  },
  uploadedImage: [],
  languagesProps: {
    languageLevelList: [],
    languageLevel: {},
  },
  skillsProps: {
    selectedMainSkillsList: [],
    selectedSecondarySkillsList: [],
  },
  keyAchievementsProps: {
    proficiency: '',
    creativity: '',
    guidanceAndMentorship: '',
    scalability: ''
  },
  positionProps: {
    position: {},
    level: {},
    mainTechnologyDropdownList: [],
    mainTechnology: {},
    customPosition: null
  },
  historyProps: {
    history: [],
    creator: {},
    updater: {},
    updatedAt: '',
    createdAt: '',
  },
  versionModalProps: {
    isModalOpen: false,
  },
  deleteModalProps: {
    isModalOpen: false,
    modalId: 'deleteModal',
    isLoading: false,
    title: '',
    name: '',
    data: {},
  },
  profileCropModal: {
    modalId: 'profileCropModal',
    isModalOpen: false,
  },
  editVersionModalProps: {
    modalId: 'editVersion',
    isModalOpen: false,
    version: {
      name: '',
      description: ''
    },
    isEdit: false,
    isSaving: false
  },
  modalProps: {
    isModalOpen: false,
    modalId: '',
    isSubmit: false,
    isEdit: false,
  },
  modalFields: {
    educationData: {
      id: '',
      name: '',
      degree: '',
      startDate: '',
      endDate: '',
    },
    certificationData: {
      id: '',
      name: '',
      link: '',
      description: '',
      isShow: false
    },
    projectData: {
      id: '',
      name: '',
      startYear: '',
      endYear: '',
      shortDescription: '',
      features: [],
      projectEmptyDescriptionIds: [],
      isSelectProject: false,
      employeeProjects: [],
      projectNamesNDA: [],
      isInternal: true,
      underNda: false,
      technologies: ''
    },
  },
  formErrors: {},
  formInputData: {
    version: {},
    isAnonymous: false,
    isShowCompany: true,
    isShowPhoto: true,
    isShowEmail: true,
    isShowVideo: true,
    isShowYears: true,
    isCustomPosition: false,
    customPosition: '',
    email: '',
    videoPresentation: '',
    professionalSummary: '',
    uploadedImage: [],
    yearsOfExperience: 0,
    educations: [],
    certifications: [],
    projects: []
  },
  versionData: {
    versionCurrent: {},
    versionList: []
  },
  template: CV_FIELD_TYPES_TEMPLATES.BLUE,
};

const getValue = (value, defaultValue) => {
  return value || defaultValue;
};

const getPhoto = (photo) => {
  let userPhoto = photo;

  if (photo) {
    const { original_link: src, name } = photo;

    userPhoto = { src, name, file: { src, name } };
  }

  return userPhoto;
};

const setDataState = (state, dataCV) => {
  const { data } = dataCV;
  const photo = getPhoto(dataCV?.photo);
  const user = getValue(dataCV?.user, {});

  const getTechnology = (list) => {
    return dataCV?.technologies?.filter((technology) => list.includes(technology.id));
  };
  const mapSkills = (skill) => {
    return { key: skill.id, value: skill.name };
  };

  state.uploadedImage = photo ? [photo] : [];
  state.languagesProps.languageLevel = getValue(
    data?.languages.find((item) => item.name === 'English'),
    {
      id: 'english',
      name: 'Englsih',
      level: data?.languages.find((lang) => lang.id === 'english')?.level || dataCV?.user?.languages?.english || '-'
    } || {}
  );
  state.strengthsProps.strengthList = getValue(data?.strengths, []);
  state.technologiesProps.technologyList = getValue(data?.technologies, []);
  state.employeesProps.employee = user;
  state.positionProps = {
    ...state.positionProps,
    position: getValue(data?.position, {}), // add default position from profile need back
    level: getValue(data?.level, {}),
    mainTechnology: dataCV?.skills?.length > 0 ? mapSkills(dataCV?.skills?.[0]) : {}
  };
  state.editVersionModalProps = {
    ...state.editVersionModalProps,
    isModalOpen: false,
    version: {}
  };
  state.formInputData = {
    ...state.formInputData,
    employeeRole: getValue(dataCV?.role, ''),
    professionalSummary: getValue(data?.summary, ''),
    experience: getValue(data?.years_experience, 0),
    educations: data?.educations.map((item) => {
      return {
        id: item.id,
        name: item.name_of_institution,
        degree: item.specialty,
        startDate: item.start_year,
        endDate: item.end_year
      };
    }),
    certifications: data?.certifications.map((item) => {
      return {
        id: item.id,
        name: item.name,
        link: item.url,
        description: item.description,
        isShow: item.show_in_header
      };
    }),
    projects: data?.projects.map((item) => {
      return {
        id: item.id,
        name: item.name,
        shortDescription: item.short_description,
        startYear: item.start_year,
        endYear: item.end_year,
        underNda: item.under_nda,
        technologies: item.technologies,
        features: item
          ?.features
          ?.map((feature, key) => { return { id: key, name: feature }; })
          ?.sort((a, b) => a.id - b.id) || [],
      };
    }),
    githubLink: getValue(dataCV?.github, ''),
    version: dataCV.version,
    versions: dataCV.versions,
    isAnonymous: data?.make_anonymous,
    isShowEmail: data?.show_email,
    isShowVideo: data?.show_video,
    isShowCompany: data?.show_company_info,
    isShowPhoto: data?.show_photo,
    isShowYears: data?.show_years_experience,
    isCustomPosition: data?.is_custom_position_title,
    customPosition: data?.custom_position_title,
    videoPresentation: getValue(data?.video, ''),
    yearsOfExperience: getValue(data?.years_experience, 0),
    email: user?.email,
  };
  state.skillsProps = {
    ...state.skillsProps,
    selectedMainSkillsList: getTechnology(data?.main_technologies),
    selectedSecondarySkillsList: getTechnology(data?.secondary_technologies)
  };
  state.keyAchievementsProps = {
    ...state.keyAchievementsProps,
    proficiency: getValue(data?.achievement?.development, ''),
    creativity: getValue(data?.achievement?.creativity, ''),
    guidanceAndMentorship: getValue(data?.achievement?.mentorship, ''),
    scalability: getValue(data?.achievement?.performance, '')
  };
  state.historyProps = {
    ...state.historyProps,
    creator: getValue(dataCV?.creator, {}),
    updater: getValue(dataCV?.updater, {}),
    updatedAt: getValue(dataCV?.updated_at, ''),
    createdAt: getValue(dataCV?.created_at, '')
  };
  state.template = getValue(data?.template, CV_FIELD_TYPES_TEMPLATES.BLUE);
  state.modalFields.projectData.isDeleteProject = false;
};

const getVersionList = (data) => {
  return data.reduce((acc, item) => {
    acc.push({
      id: item.id,
      name: item.name,
      description: item.description,
      createDate: item.created_at,
      updateDate: item.updated_at
    });

    return acc;
  }, []);
};

const uploadPhoto = async (newUploadedImage) => {
  if (newUploadedImage.length > 0) {
    const file = await urlToFile(newUploadedImage[0]?.src, newUploadedImage[0]?.name);
    const fileId = await sendFiles([file]);
    return fileId?.data[0];
  }
  return null;
};

const getRequestData = (getState) => {
  const {
    formInputData: {
      isAnonymous, isShowEmail, videoPresentation, professionalSummary, yearsOfExperience, isShowYears, educations,
      certifications, projects, isShowCompany, isShowPhoto, isShowVideo, isCustomPosition, customPosition
    },
    languagesProps: {
      languageLevel
    },
    positionProps: {
      position, level, mainTechnology
    },
    skillsProps: {
      selectedMainSkillsList, selectedSecondarySkillsList
    },
    keyAchievementsProps: {
      proficiency, creativity, guidanceAndMentorship, scalability
    },
    template,
    modalFields: {
      projectData,
      certificationData,
      educationData,
    },
    deleteModalProps: {
      name: deleteName
    }
  } = getState().systemsCVGeneratorV1;

  const request = {
    template,
    make_anonymous: isAnonymous,
    show_email: isShowEmail,
    show_video: isShowVideo,
    show_photo: isShowPhoto,
    show_company_info: isShowCompany,
    show_years_experience: isShowYears,
    is_custom_position_title: isCustomPosition,
    ...customPosition && { custom_position_title: customPosition },
    achievement: {
      ...proficiency && { development: proficiency },
      ...creativity && { creativity },
      ...guidanceAndMentorship && { mentorship: guidanceAndMentorship },
      ...scalability && { performance: scalability },
    },
    ...professionalSummary && { summary: professionalSummary },
    ...videoPresentation && { video: videoPresentation },
    ...yearsOfExperience && { years_experience: parseInt(yearsOfExperience) },
    ...position && { position },
    ...level && { level },
    ...languageLevel && { languages: [languageLevel] },
    educations: getRequestItem(educations, educationData, 'education', !!deleteName),
    certifications: getRequestItem(certifications, certificationData, 'certification', !!deleteName),
    projects: getRequestItem(projects, projectData, 'project', !!deleteName),
  };

  if (mainTechnology?.key) {
    request.skills = [mainTechnology.key];
  }
  if (selectedMainSkillsList.length > 0) {
    request.main_technologies = selectedMainSkillsList.map((item) => item.id);
  }
  if (selectedSecondarySkillsList.length > 0) {
    request.secondary_technologies = selectedSecondarySkillsList.map((item) => item.id);
  }

  return request;
};

export const fetchEmployeeProjectsAsyncV1 = createAsyncThunk(
  'systemsCVGenerator/fetchEmployeeProjectsAsyncV1',
  async ({ isSelect = false, isNewCV = false }, { rejectWithValue, getState }) => {
    try {
      const { query: { user_id: userId } } = Router;
      const {
        systemsCVGeneratorV1: {
          query,
          modalFields: {
            projectData: { projectEmptyDescriptionIds }
          },
          formInputData: { projects: projectsList = [] }
        }
      } = getState();

      const params = { ...query, per_page: LIST_SIZE_PER_PAGE_25 };

      const { data: { data }, status } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/users/${userId}/projects`,
        { params }
      );

      let newData;

      if (isSelect) {
        const projects = data
          .reduce((acc, item) => [
            ...acc,
            {
              id: item.project.id,
              underNda: item.project.under_nda,
              name: item.project.name,
              shortDescription: item.project.short_description,
              startDate: item.start_date,
              endDate: item.end_date,
            }
          ], []);
        const projectNamesNDA = data.filter((obj) => obj.project.under_nda).map((item) => item.project.name);
        const removeDublicateProjects = projects.filter((item) => !projectsList
          .some((project) => project.id === item.id));

        newData = {
          projectNamesNDA,
          employeeProjects: sortBy(removeDublicateProjects, 'name'),
          projectEmptyDescriptionIds: [...projectEmptyDescriptionIds, ...addEmptyDescriptionIds(projects || [])]
        };
      } else {
        const projectNamesNDA = data.filter((obj) => obj.project.under_nda).map((item) => item.project.name);

        newData = { projectNamesNDA };

        if (isNewCV) {
          const newProjects = data.length > 3 ? data.slice(0, 3) : data;

          const projects = newProjects.map((item) => {
            return ({
              id: item.project.id,
              name: item.project.name,
              short_description: item.project.short_description ? item.project.short_description : undefined,
              start_year: item.start_date ? new Date(item.start_date).getFullYear() : null,
              end_year: item.end_date ? new Date(item.end_date).getFullYear() : null,
            });
          });

          newData = {
            ...newData,
            projects,
            projectEmptyDescriptionIds: [...projectEmptyDescriptionIds, ...addEmptyDescriptionIds(projects || [])]
          };
        }
      }

      return status === OK ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCVSGeneratorV1Async = createAsyncThunk(
  'systemsCVGeneratorV1/fetchCVSGeneratorV1Async',
  async (arg, { rejectWithValue, getState, dispatch }) => {
    const { permissions, id: userId } = getState().user.profile;

    try {
      const { query } = Router;
      const isManagementCV = isAllowed([CV_MANAGEMENT], permissions) || query.user_id === userId;

      const params = {
        filters: {
          user_id: query.user_id,
        },
        with: ['user'],
      };

      const { data, status } = await axios.get(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cvs`, { params });

      let versionCurrent = {
        ...data?.cv,
      };

      if (data?.data.length === 0 && isManagementCV) {
        const {
          data: userData
        } = await axios.get(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/users/${query.user_id}/cvs`);
        const projectsRes = await dispatch(fetchEmployeeProjectsAsyncV1({ isNewCV: true }));

        const requestBody = prepareRequestBody({
          user_id: Router.query.user_id,
          name: 'CV #1',
          data: {
            template: CV_FIELD_TYPES_TEMPLATES.BLUE,
            projects: projectsRes.payload.projects,
            make_anonymous: false,
            show_email: true,
            show_years_experience: true,
            show_video: true,
            show_photo: true,
            show_company_info: true,
            summary: '',
            languages: [{
              id: 'english',
              name: 'Englsih',
              level: userData?.user?.languages?.english || '-'
            }] || [],
            userName: userData?.user?.name || '',
            userMail: userData?.user?.email || '',
          },
          user: userData?.user || {},
        });

        const { data: newVersionData, status: newVersionStatus } = await axios.post(
          `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cvs`,
          requestBody
        );

        if (newVersionStatus !== OK && newVersionStatus !== CREATED) {
          return rejectWithValue(data.message);
        }

        versionCurrent = {
          id: newVersionData.id,
          name: newVersionData.name,
          description: newVersionData.description,
          createDate: newVersionData.created_at,
          updateDate: newVersionData.updated_at,
          data: newVersionData.data,
          user: newVersionData.user,
        };
      }

      const newData = {
        fieldsData: data?.cv,
        versionData: {
          versionList: getVersionList(data?.data),
          versionCurrent,
        }
      };

      return status === OK ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCVItemGeneratorV1Async = createAsyncThunk(
  'systemsCVGeneratorV1/fetchCVItemGeneratorV1Async',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { versionList, versionCurrent } = getState().systemsCVGeneratorV1.versionData;

      const { data, status } = await axios.get(`/cvs/${versionCurrent.id}`);

      const newData = {
        fieldsData: data,
        versionData: {
          versionList,
          versionCurrent: {
            ...data,
            id: data.id,
            name: data.name,
            description: data.description,
            createDate: data.created_at,
            updateDate: data.updated_at,
            projects: data.projects
          }
        },
      };

      return status === OK ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCV1VersionAsync = createAsyncThunk(
  'systemsCVGeneratorV1/addCV1VersionAsync',
  async (arg, { rejectWithValue, getState, dispatch }) => {
    try {
      const { version } = getState().systemsCVGeneratorV1.editVersionModalProps;
      const { languages } = getState().user.profile;

      const projectsRes = await dispatch(fetchEmployeeProjectsAsyncV1({ isNewCV: true }));
      const requestBody = prepareRequestBody({
        user_id: Router.query.user_id,
        name: version.name,
        description: version.description,
        data: {
          template: CV_FIELD_TYPES_TEMPLATES.BLUE,
          projects: projectsRes.payload.projects,
          make_anonymous: false,
          show_email: true,
          show_years_experience: true,
          show_video: true,
          show_photo: true,
          show_company_info: true,
          summary: '',
          languages: [
            {
              name: 'english',
              level: languages?.english
            }
          ],
        }
      });

      const { data, status } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cvs`,
        requestBody
      );

      return (status === OK || status === CREATED) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editCV1Async = createAsyncThunk(
  'systemsCVGeneratorV1/editCV1Async',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        uploadedImage,
        versionData: {
          versionCurrent,
          versionList
        },
      } = getState().systemsCVGeneratorV1;

      if (arg?.validateDataForCheck) {
        const errors = validateErrors(
          arg?.validateDataForCheck?.fields,
          arg?.validateDataForCheck?.validationFunction,
          arg?.validateDataForCheck?.dependencies
        );

        if (errors) {
          return rejectWithValue({ isFormValidation: true, errors });
        }
      }

      const {
        editVersionModalProps: {
          isModalOpen,
          version: updatedVersion,
        },
        uploadedImage: oldImage
      } = getState().systemsCVGeneratorV1;

      const uploadPhotoId = await uploadPhoto(uploadedImage);
      const request = getRequestData(getState);

      const body = {
        name: versionCurrent.name,
        description: isModalOpen ? updatedVersion.description : versionCurrent.description,
        user_id: Router.query.user_id,
        data: request
      };

      if (uploadPhotoId) {
        body.photo_id = uploadPhotoId;
      } else if (oldImage.length === 0) {
        body.photo_id = null;
      }

      const {
        data, status
      } = await axios.put(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cvs/${versionCurrent.id}`, body);

      const newData = {
        fieldsData: data,
        versionData: {
          versionList,
          versionCurrent: {
            ...data,
            id: data.id,
            name: data.name,
            description: data.description,
            createDate: data.created_at,
            updateDate: data.updated_at,
          }
        }
      };

      return (status === OK || status === CREATED) ? newData : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCV1VersionAsync = createAsyncThunk(
  'systemsCVGeneratorV1/deleteCV1VersionAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { data: version } = getState().systemsCVGeneratorV1.deleteModalProps;

      const { data, status } = await axios.delete(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cvs/${version.id}`
      );

      return (status === OK || status === CREATED || status === NO_CONTENT) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchUserTechnologiesAsyncV1 = createAsyncThunk(
  'systemsCVGenerator/fetchUserTechnologiesAsyncV1',
  async (arg, { rejectWithValue }) => {
    const { query: { user_id: userId } } = Router;
    const params = {
      filters: {
        user: { id: [userId] },
        technology: { show_active: true },
      },
      with: ['technology'],
    };

    try {
      const { data, status } = await axios.get('/user_technologies', { params });

      const technologyList = data.data.reduce((acc, { technology }) => {
        return technology ? [...acc, { name: technology.name, id: technology.id }] : acc;
      }, []);

      return status === OK ? technologyList : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUserQualitiesAsyncV1 = createAsyncThunk(
  'systemsCVGenerator/fetchUserQualitiesAsyncV1',
  async (arg, { rejectWithValue }) => {
    try {
      const filters = { filters: { type: 'skill' } };
      const { data, status } = await axios.get('/cv_qualities', { params: filters });
      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsCVGeneratorV1Slice = createSlice({
  name: 'systemsCVGeneratorV1',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetDeleteModal: (state) => {
      state.deleteModalProps = initialState.deleteModalProps;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setEmployeesProps: (state, action) => {
      state.employeesProps = { ...state.employeesProps, ...action.payload };
    },
    setData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setVersionData: (state, action) => {
      state.versionData = { ...state.versionData, ...action.payload };
    },
    toggleVersionModal: (state, action) => {
      state.versionModalProps.isModalOpen = action.payload.isModalOpen;
    },
    toggleDeleteModal: (state, action) => {
      state.deleteModalProps = { ...state.deleteModalProps, ...action.payload };
    },
    setEditVersionModal: (state, action) => {
      state.editVersionModalProps = { ...state.editVersionModalProps, ...action.payload };
    },
    toggleModal: (state, action) => {
      state.modalProps = { ...state.modalProps, ...action.payload };
    },
    setModalFields: (state, action) => {
      state.modalFields = {
        ...state.modalFields,
        [action.payload.fieldsName]: {
          ...state.modalFields[action.payload.fieldsName],
          ...action.payload.fieldsData
        }
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.formErrors = initialState.formErrors;
    },
    setLanguagesProps: (state, action) => {
      state.languagesProps = { ...state.languagesProps, ...action.payload };
    },
    setPositionProps: (state, action) => {
      state.positionProps = { ...state.positionProps, ...action.payload };
    },
    setAchievementProps: (state, action) => {
      state.keyAchievementsProps = { ...state.keyAchievementsProps, ...action.payload };
    },
    setTechnologyProps: (state, action) => {
      state.skillsProps = { ...state.skillsProps, ...action.payload };
    },
    setEditVersionModalFields: (state, action) => {
      state.editVersionModalProps = { ...state.editVersionModalProps, ...action.payload };
    },
    setCropModalData: (state, action) => {
      state.profileCropModal = { ...state.profileCropModal, ...action.payload };
    },
    setFormInput: (state, action) => {
      state.formInputData = { ...state.formInputData, ...action.payload };
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
    },
    setValidateDataForCheck: (state, action) => {
      state.validateDataForCheck = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCVSGeneratorV1Async.pending, (state) => {
        state.isLoading = true;
        state.cvsPdfData = null;
      })
      .addCase(fetchCVSGeneratorV1Async.fulfilled, (state, action) => {
        state.isLoading = false;

        if (!action.meta.arg?.isNotUpdateData) {
          setDataState(state, action.payload.fieldsData);
        }

        if (action.meta.arg?.isDropdownList) {
          state.versionData.versionList = action.payload.versionData.versionList;
        } else {
          state.versionData = action.payload.versionData;
        }
      })
      .addCase(fetchCVSGeneratorV1Async.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.editVersionModalProps.isModalOpen = false;
      });
    builder
      .addCase(fetchCVItemGeneratorV1Async.pending, (state) => {
        state.isLoading = true;
        state.cvsPdfData = null;
      })
      .addCase(fetchCVItemGeneratorV1Async.fulfilled, (state, action) => {
        setDataState(state, action.payload.fieldsData);

        state.isLoading = false;
        state.versionData = action.payload.versionData;
      })
      .addCase(fetchCVItemGeneratorV1Async.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.editVersionModalProps.isModalOpen = false;
        state.editVersionModalProps.version = {};
      });
    builder
      .addCase(addCV1VersionAsync.rejected, (state, action) => {
        state.editVersionModalProps.isSubmit = false;
        state.editVersionModalErrors = action.payload;
      });
    builder
      .addCase(editCV1Async.pending, (state) => {
        state.isLoading = true;
        state.modalProps.isSubmit = true;
        state.deleteModalProps.isLoading = true;
      })
      .addCase(editCV1Async.fulfilled, (state, action) => {
        setDataState(state, action.payload.fieldsData);

        state.modalProps.isSubmit = false;
        state.modalProps.isModalOpen = false;
        state.modalFields = initialState.modalFields;
        state.versionData = action.payload.versionData;
        state.deleteModalProps = initialState.deleteModalProps;
        state.formErrors = initialState.formErrors;
        state.isLoading = false;
      })
      .addCase(editCV1Async.rejected, (state, action) => {
        state.formErrors = action?.payload?.errors || {};
        state.modalProps.isSubmit = false;
        state.editVersionModalProps.isSaving = false;
        state.deleteModalProps.isLoading = false;
        state.isLoading = false;
      });
    builder
      .addCase(deleteCV1VersionAsync.pending, (state) => {
        state.deleteModalProps.isLoading = true;
      })
      .addCase(deleteCV1VersionAsync.fulfilled, (state) => {
        state.deleteModalProps.isLoading = false;
        state.deleteModalProps.isModalOpen = false;
      })
      .addCase(deleteCV1VersionAsync.rejected, (state) => {
        state.deleteModalProps.isLoading = false;
      });
    builder
      .addCase(fetchEmployeeProjectsAsyncV1.pending, (state) => {
        state.isProjectLoading = true;
      })
      .addCase(fetchEmployeeProjectsAsyncV1.fulfilled, (state, action) => {
        state.isProjectLoading = false;

        if (action.payload?.employeeProjects) {
          state.modalFields.projectData.employeeProjects = action.payload.employeeProjects;
        }

        if (action.payload?.projectNamesNDA) {
          state.modalFields.projectData.projectNamesNDA = action.payload.projectNamesNDA;
        }
      })
      .addCase(fetchEmployeeProjectsAsyncV1.rejected, (state, action) => {
        state.isProjectLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchUserTechnologiesAsyncV1.pending, (state) => {
        state.isTechnologyLoading = true;
      })
      .addCase(fetchUserTechnologiesAsyncV1.fulfilled, (state, action) => {
        state.isTechnologyLoading = false;
        state.technologiesProps.technologyList = action.payload;
      })
      .addCase(fetchUserTechnologiesAsyncV1.rejected, (state, action) => {
        state.isTechnologyLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchUserQualitiesAsyncV1.fulfilled, (state, action) => {
        state.positionProps.mainTechnologyDropdownList = action.payload;
      });
  },
});

export const {
  setData,
  setQueryFilter,
  setQuery,
  setEmployeesProps,
  resetState,
  resetDeleteModal,
  toggleDeleteModal,
  toggleModal,
  setEditVersionModalFields,
  setCropModalData,
  setLanguagesProps,
  setFormInput,
  setPositionProps,
  setAchievementProps,
  setTechnologyProps,
  toggleVersionModal,
  setVersionData,
  setEditVersionModal,
  setModalFields,
  resetModalFields,
  setTemplate,
} = systemsCVGeneratorV1Slice.actions;

export default systemsCVGeneratorV1Slice.reducer;
