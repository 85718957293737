export const CV_FIELD_TYPES = {
  VERSION: 'version',
  CERTIFICATION: 'certification',
  EDUCATION: 'education',
  PROJECT: 'project',
};
export const CV_FIELD_TYPES_TEMPLATES = {
  BLUE: 'blue',
  DARK: 'dark',
  DARK_BLUE: 'darkblue',
};

export const CV_TEMPLATES_STYLES = {
  [CV_FIELD_TYPES_TEMPLATES.BLUE]: {
    font: '#000',
    wavyCheck: {
      fill: '#0072FF',
      color: '#FFF',
    },
    email: {
      fill: '#363A42',
      color: '#FFF',
    },
    youtube: {
      fill: '#363A42',
      color: '#FFF',
    },
    vectorColor: '#000',
  },
  [CV_FIELD_TYPES_TEMPLATES.DARK]: {
    font: '#FFF',
    wavyCheck: {
      fill: '#FFF',
      color: '#000',
    },
    email: {
      fill: '#FFF',
      color: '#363A42',
    },
    youtube: {
      fill: '#FFF',
      color: '#363A42',
    },
    vectorColor: '#FFF',
  },
  [CV_FIELD_TYPES_TEMPLATES.DARK_BLUE]: {
    font: '#FFF',
    wavyCheck: {
      fill: '#000',
      color: '#FFF',
    },
    email: {
      fill: '#FFF',
      color: '#363A42',
    },
    youtube: {
      fill: '#FFF',
      color: '#363A42',
    },
    vectorColor: '#FFF',
  },
};

export const CV_SECTION_KEYS = {
  CERTIFICATIONS: 'certifications',
  EDUCATIONS: 'educations',
  PROJECTS: 'projects',
};

export const CV_SECTION_LIST_KEYS = [
  CV_SECTION_KEYS.CERTIFICATIONS,
  CV_SECTION_KEYS.EDUCATIONS,
  CV_SECTION_KEYS.PROJECTS,
];
